// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import {clearGroupOrder} from "../../utility/Utils"

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    isLoading: false,
    isRequestCompleted: false,
    isTokenVerified: null,
    isPasswordReset: false
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      clearGroupOrder()
    },
    setRequestCompleted: (state, action) => {
      state.isRequestCompleted = action.payload
    },
    setTokenVerified: (state, action) => {
      state.isTokenVerified = action.payload
    },
    setTokenVerifiedFalse: state => {
      state.isTokenVerified = false
    },
    setPasswordReset: (state, action) => {
      state.isPasswordReset = action.payload
    }
  }
})

export const { handleLogin, handleLogout, setPasswordReset, setRequestCompleted, setTokenVerified, setTokenVerifiedFalse } = authSlice.actions

export default authSlice.reducer
